import React from 'react';

import { MobxNextPageContext } from '@app/stores/root/types/Context';
import { IndexPage } from '@app/core/components/index/IndexPage';

const Index = () => <IndexPage />;

Index.getInitialProps = async (ctx: MobxNextPageContext) => {
  const { query, store, asPath } = ctx;

  return {
    url: asPath,
  };
};

export default Index;
