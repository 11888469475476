import { Modes } from '@app/core/components/process/ProcessShowModes';
import { ProcessStatus } from '@app/types/Process';
import { UserStatus } from '@app/types/User';
import { ImportType } from '@app/stores/import/actions';

export const ROUTER = {
  processList: (status: ProcessStatus) => ({
    href: '/process/list/[status]',
    as: `/process/list/${status}`,
  }),
  editFer: projectId => ({
    href: `/process/[id]?mode=${Modes.EditFer}`,
    as: `/process/${projectId}?mode=${Modes.EditFer}`,
  }),
  showFer: projectId => ({
    href: `/process/[id]?mode=${Modes.Fer}`,
    as: `/process/${projectId}?mode=${Modes.Fer}`,
  }),
  importList: {
    href: '/import/list',
    as: '/import/list',
  },
  import: (type: ImportType) => ({
    href: '/import/[type]',
    as: `/import/${type}`,
  }),
  profile: {
    href: '/profile',
    as: '/profile',
  },
  userList: (folder: UserStatus.Active | UserStatus.NotActive) => ({
    href: `/users/list/[status]`,
    as: `/users/list/${folder}`,
  }),
  userListTab: (
    folder: UserStatus.Active | UserStatus.NotActive,
    tab: string,
  ) => ({
    href: `/users/list/[status]?mode=${tab}`,
    as: `/users/list/${folder}?mode=${tab}`,
  }),
  userAdd: {
    href: '/users/add',
    as: '/users/add',
  },
  userDetail: (id: string) => ({
    href: '/users/edit/[id]',
    as: `/users/edit/${id}`,
  }),
};
