import * as React from 'react';

import { ProcessStatus } from '@app/types/Process';
import { Link } from '@app/core/primitives/Link/Link';
import { ImportType } from '@app/stores/import/actions';
import { ROUTER } from '@app/core/router';
import { UserStatus } from '@app/types/User';

import { Links } from './styled';

export const IndexPage = () => {
  return (
    <Links>
      <Link
        key="importProc"
        {...ROUTER.import(ImportType.Process)}
        passHref={true}
      >
        Импорт справочников
      </Link>
      <Link key="importProc" {...ROUTER.importList} passHref={true}>
        Список импорта
      </Link>
      <Link
        key="processes"
        {...ROUTER.processList(ProcessStatus.Approved)}
        passHref={true}
      >
        Список процессов
      </Link>
      <Link key="users" {...ROUTER.userList(UserStatus.Active)} passHref={true}>
        Список пользователей
      </Link>
      <Link key="profile" {...ROUTER.profile} passHref={true}>
        Редактирование профиля
      </Link>
    </Links>
  );
};
