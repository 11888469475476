export enum Modes {
  Show = 'show',
  Approve = 'approve',
  Gesn = 'gesn',
  Fer = 'fer',
  EditFer = 'editFer',
  Tvn = 'tvn',
}

export enum IndexModes {
  Smr = 'smr',
  Expenses = 'expenses',
}
