import styled from 'styled-components';

export const Links = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 143% */

  letter-spacing: 0.15px;

  /* android/blue-lighter */

  color: #304591;
  margin: 20px;

  & a {
    display: block;
    font-size: 16px;
    line-height: 20px;
    padding: 10px 0;
  }

  & a:before {
    content: '- ';
  }
`;
