export const apiErrorHandler = async error => {
  console.log(error);
  if (error.data) {
    if (typeof error.data.message !== 'string') {
      error.data.message = 'Ошибка на сервере';
    }

    throw error.data;
  }
};
